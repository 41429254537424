import React from "react"
import classnames from "classnames/bind"
import HeadingWithText from "./HeadingWithText"
import styles from "./ImageWithText.module.css"

const cx = classnames.bind(styles)

const ImageWithText = props => {
  const {
    content: { image, heading, text, offset, imageRight },
  } = props

  return (
    <div
      className={cx("container", {
        imageRight,
      })}
    >
      <div className={styles.imgContainer}>
        <img src={image} />
      </div>
      <div className={styles.textContainer}>
        <HeadingWithText
          className={styles.text}
          heading={heading}
          text={text}
        />
      </div>
    </div>
  )
}

export default ImageWithText
