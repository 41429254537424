import React from "react"
import styles from "./FullWidthText.module.css"

const FullWidthText = props => {
  const {
    content: { heading, text },
  } = props

  return (
    <div className={styles.container}>
      <h3>{heading}</h3>
      <p>{text}</p>
    </div>
  )
}

export default FullWidthText
