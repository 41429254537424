import React from "react"
import HeadingWithText from "./HeadingWithText"
import styles from "./ImageWithOffsetText.module.css"

const ImageWithOffsetText = props => {
  const {
    content: { image, heading, text, placement, offset },
  } = props

  let offsetTextPosition = {}

  if (placement) {
    placement.split(",").forEach(pos => (offsetTextPosition[pos] = "0"))
    offsetTextPosition["position"] = "absolute"
    offsetTextPosition["margin"] = 0
  }

  if (offset) {
    offsetTextPosition["transform"] = "translateY(" + offset + ")"
  }

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img src={image} />
      </div>
      <HeadingWithText
        className={styles.offsetText}
        style={offsetTextPosition}
        heading={heading}
        text={text}
      />
    </div>
  )
}

export default ImageWithOffsetText
