import React from "react"
import classnames from "classnames/bind"
import styles from "./ImageFull.module.css"

const cx = classnames.bind(styles)

const ImageFull = props => {
  const {
    content: { image, isFullWidth, bgColor, noPadding, alt },
  } = props
  return (
    <div
      className={cx({
        fullWidthImg: isFullWidth,
        contentWidthImg: !isFullWidth,
        noPadding: noPadding,
      })}
      style={bgColor && { background: bgColor }}
    >
      <img src={image} alt={alt} />
      {alt && <p>{alt}</p>}
    </div>
  )
}

export default ImageFull
