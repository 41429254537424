const caseStudiesContent = [
  {
    id: "/work/macmillan/",
    content: [
      {
        type: "HeaderText",
        content: {
          title: "RESEARCH & IDEATION",
          subtitle: "Macmillan Digital Services",
          text:
            "Researching how the charity might help people living with cancer find and access digital forms of support, by designing a digital, mid-fidelity prototype.",
          alignment: "left",
        },
      },
      {
        type: "ImageFull",
        content: {
          image: "/casestudies/macmillan/macmillan_hero.jpg",
          isFullWidth: true,
        },
      },
      {
        type: "TextColumns",
        content: [
          {
            heading: "The Client",
            text:
              "Macmillan Cancer Support is one of the largest British charities and provides specialist health care, information and financial support to people affected by cancer. It also looks at the social, emotional and practical impact cancer can have, and campaigns for better cancer care. Macmillan’s goal is to improve the lives of everyone who has cancer in the UK.",
          },
          {
            heading: "Our Mission",
            text:
              "In 2020, we supported the Innovation team at Macmillan with research into the potential an online marketplace could bring by enabling people living with cancer to find and access digital forms of support. To support our research findings, a digital prototype was created and tested with people living with and affected by cancer.",
          },
        ],
      },
      {
        type: "ImageWithOffsetText",
        content: {
          image: "/casestudies/macmillan/macmillan_1.jpg",
          heading: "Browse on any device",
          text:
            "The prototype was designed as a web platform, with a responsive interface that can be viewed on any mobile device to make it as easy as possible for people living with cancer to access the support they need. ",
        },
      },
      {
        type: "ImageFull",
        content: {
          image: "/casestudies/macmillan/macmillan_2.png",
          isFullWidth: false,
          bgColor: "#F7F6FE",
        },
      },
      {
        type: "FullWidthText",
        content: {
          heading:
            "Empowering people to find digital services through multiple seamless user flows",
          text:
            "The prototype has multiple user flows to enable the user to browse the content in a way that suits them. ",
        },
      },
      {
        type: "ImageWithOffsetText",
        content: {
          image: "/casestudies/macmillan/macmillan_3.jpg",
          heading:
            "Making the experience feel personal through personalized questions",
          text:
            "Users can answer a series of questions to receive a tailored list of digital support services, or they can explore the different types of services available by selecting the option to browse all, or browse a specific type of support service. ",
          offset: "-15%",
        },
      },
      {
        type: "ImageFull",
        content: {
          image: "/casestudies/macmillan/macmillan_4.jpg",
          isFullWidth: false,
          noPadding: true,
          alt: "Filter Page: physical support services.",
        },
      },
      {
        type: "ImageWithText",
        content: {
          image: "/casestudies/macmillan/macmillan_4_2.jpg",
          imageRight: true,
          heading: "A card based interface for a clear overview of services",
          text:
            "Individual support services are presented as cards. Each card is displayed in the same way, ensuring consistency and familiarity. Users can filter the available services using the tabs and the filter menu to further refine results.",
        },
      },
      {
        type: "ImageWithOffsetText",
        content: {
          image: "/casestudies/macmillan/macmillan_5.jpg",
          heading: "Reviews and user recommendations",
          text:
            "Users can learn more about the digital support services available by looking at those most recommended by their peers on the landing page, or by reading reviews left by people living with cancer.",
          placement: "top,right",
          offset: "-20%",
        },
      },
    ],
  },
  {
    id: "/work/lawcareers/",
    content: [],
  },
]

export { caseStudiesContent }
