import React from "react"
import styles from "./TextColumns.module.css"
import HeadingWithText from "./HeadingWithText"

const TextColumns = props => {
  const { content } = props
  return (
    <div className={styles.textColumns}>
      {content.map(col => (
        <HeadingWithText
          heading={col.heading}
          text={col.text}
          key={col.heading}
        />
      ))}
    </div>
  )
}

export default TextColumns
