import React from "react"
import classNames from "classnames/bind"
import styles from "./Badge.module.css"
const cx = classNames.bind(styles)

const Badge = ({ title, color = "Blue" }) => {
  return (
    <h3 className={cx(["sectionTitle", `sectionTitle${color}`])}>{title}</h3>
  )
}

export default Badge
