import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styles from "./case-study.module.css"

import SEO from "../components/seo"
import Badge from "../components/Badge/Badge"
import ExploreProjects from "../components/caseStudyComponents/ExploreProjects"
import FullWidthText from "../components/caseStudyComponents/FullWidthText"
import HeaderText from "../components/caseStudyComponents/HeaderText"
import ImageFull from "../components/caseStudyComponents/ImageFull"
import ImageWithOffsetText from "../components/caseStudyComponents/ImageWithOffsetText"
import ImageWithText from "../components/caseStudyComponents/ImageWithText"
import TextColumns from "../components/caseStudyComponents/TextColumns"
import { caseStudiesContent } from "../content/work/caseStudiesPageContent"

export default ({ data }) => {
  const caseStudy = data.markdownRemark
  const caseStudyDetails = caseStudy.frontmatter
  const allCaseStudies = data.allMarkdownRemark.edges
  const hasCaseStudy = caseStudyDetails.hasCaseStudy
  const caseStudyContent = caseStudiesContent.filter(
    page => page.id === caseStudy.fields.slug
  )[0]

  const exploreProjects = []
  for (let i = 0; i < allCaseStudies.length; i++) {
    if (allCaseStudies[i].node.fields.slug === caseStudy.fields.slug) {
      if (i === 0) {
        exploreProjects.push(allCaseStudies[i + 1], allCaseStudies[i + 2])
      } else if (i === allCaseStudies.length - 1) {
        exploreProjects.push(allCaseStudies[i - 2], allCaseStudies[i - 1])
      } else {
        exploreProjects.push(allCaseStudies[i - 1], allCaseStudies[i + 1])
      }
    }
  }

  const selectComponent = (item, index) => {
    switch (item.type) {
      case "HeaderText":
        return <HeaderText content={item.content} key={index} />
      case "ImageFull":
        return <ImageFull content={item.content} key={index} />
      case "TextColumns":
        return <TextColumns content={item.content} key={index} />
      case "ImageWithText":
        return <ImageWithText content={item.content} key={index} />
      case "ImageWithOffsetText":
        return <ImageWithOffsetText content={item.content} key={index} />
      case "FullWidthText":
        return <FullWidthText content={item.content} key={index} />
      default:
        return ""
    }
  }
  return (
    <Layout>
      <SEO
        title={`${caseStudyDetails.title} x Undefined - Digital Product Studio`}
      />
      {hasCaseStudy ? (
        caseStudyContent.content.map((item, index) =>
          selectComponent(item, index)
        )
      ) : (
        <div className={styles.container}>
          <HeaderText
            content={{
              title: caseStudyDetails.category,
              subtitle: caseStudyDetails.title,
              text: caseStudyDetails.text,
              alignment: "left",
            }}
          />
          <ImageFull
            content={{
              image: caseStudyDetails.featuredImage,
              noPadding: true,
            }}
          />
          <div className={styles.comingSoon}>
            <div className={styles.comingSoonContent}>
              <h3>Detailed case study coming soon! </h3>
              <p>
                Got any questions about this project? Don't hesitate to{" "}
                <a href="mailto:siebe@thisisundefined.com">get in touch</a>
              </p>
            </div>
          </div>
        </div>
      )}
      <ExploreProjects projects={exploreProjects} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { posttype: { eq: "case-study" } } }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            posttype
            featuredImage
            bgColor
            color
            description
            text
            tags
            category
            linkURL
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        posttype
        featuredImage
        bgColor
        color
        text
        tags
        category
        hasCaseStudy
      }
      id
      fields {
        slug
      }
    }
  }
`
