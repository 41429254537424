import React from "react"
import Badge from "../Badge/Badge"
import styles from "./HeaderText.module.css"

const HeaderText = props => {
  const {
    content: { title, subtitle, text, alignment },
    index,
  } = props

  let textAlign

  if (alignment === "left") {
    textAlign = {
      alignItems: "flex-start",
    }
  } else if (alignment === "center") {
    textAlign = {
      alignItems: "center",
      textAlign: "center",
    }
  } else if (alignment === "right") {
    textAlign = {
      alignItems: "flex-end",
      textAlign: "right",
    }
  }

  return (
    <div className={styles.container} style={textAlign} key="1">
      <Badge title={title} color="Purple" />
      <h3 className={styles.title}>{subtitle}</h3>
      <p className={styles.small_text}>{text}</p>
    </div>
  )
}

export default HeaderText
