import React from "react"
import styles from "./ExploreProjects.module.css"

const ExploreProjects = props => {
  const { projects } = props

  return (
    <div className={styles.container}>
      <h3>
        Explore more<span>.</span>
      </h3>
      <div className={styles.projectsWrapper}>
        {projects.map(proj => {
          const {
            node: {
              frontmatter: { title, category, featuredImage, description },
              fields: { slug },
              id,
            },
          } = proj

          return (
            <a href={slug} key={id} className={styles.project}>
              <div className={styles.projectImage}>
                <img src={featuredImage} />
              </div>
              {/*<h5></h5>*/}
              <h4>
                {title}
                {/*<span className={styles.category}>{category}</span>*/}
              </h4>
              <p>{description}</p>
              <div className={styles.btn}>View project</div>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default ExploreProjects
