import React from "react"
import styles from "./HeadingWithText.module.css"

const HeadingWithText = props => {
  const { className, heading, text, style } = props
  return (
    <div
      className={`${className ? className : ""} ${styles.container}`}
      style={style}
    >
      <h4>{heading}</h4>
      <p>{text}</p>
    </div>
  )
}

export default HeadingWithText
